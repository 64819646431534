@use 'src/styles/theme';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 9px;
  flex-basis: 100%;
  flex-shrink: 1;
  overflow: hidden;

  [class*='BlockeadTabs__Pane'] {
    padding-top: 0;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  // TODO: Remove this when adding the proper theme at blockhead
  [role='tablist'] {
    border-color: theme.$l4;
  }
}
