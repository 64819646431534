.deleteButtonContainer {
  height: 12px;
  margin-right: 24px;
  position: relative;
  width: 10px;
}

.deleteButton {
  position: absolute;
  left: -10px;
  top: -9px;

  &,
  &:active:not([disabled]) {
    box-shadow: none;
  }
}
