$font-size-variable: --font-size;

.badge {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: var($font-size-variable);
  font-weight: 700;
  justify-content: center;
  line-height: 1;
  padding: 4px 6px;
  text-transform: uppercase;
}

:export {
  fontSizeVariable: $font-size-variable;
}
