@use 'sass:color';
@use 'src/styles/theme';
@use 'src/styles/utils';

.root {
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.header {
  background-color: inherit;
  border: none;
  border-bottom: 2px solid theme.$l4;
  color: theme.$d1;
  cursor: pointer;
  padding: 16px 24px;
  outline: none;
}

.title {
  @include utils.with-ellipsis(1);
}

.backIcon {
  color: theme.$d1;
  transition: color 250ms ease-in-out;

  &,
  &:active:not([disabled]) {
    box-shadow: none;
  }

  .header[data-hovered='true']:not([disabled]) & {
    color: color.scale(theme.$d1, $lightness: 30%);
  }
}

.list {
  :global {
    .Blockhead__ListItem__GridCell {
      padding: 0;
    }
  }
}
