@use 'src/styles/theme';

.root {
  background-color: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(50px);
  color: theme.$white;

  @include theme.breakpointDown('md') {
    text-align: center;
  }
}
