@use 'src/styles/theme';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 110px);
  overflow: hidden;
  border-radius: 24px;

  @include theme.breakpointDown('md') {
    max-height: unset;
    height: 100vh;
    border-radius: 0;
    overflow-y: hidden;
  }
}
