@use 'src/styles/theme';

.container {
  color: theme.$white;
  width: 100%;
}

.idleContainer {
  flex-wrap: wrap;
  gap: 12px;
  padding: 8px 8px 8px 16px;

  @include theme.breakpointDown('md') {
    padding: 0;
  }
}

.badge {
  flex-shrink: 0;
}

.bigPlayButton {
  flex-shrink: 0;
  font-size: 2.43em;
  height: 80px;
  width: 80px;

  @include theme.breakpointDown('md') {
    font-size: 1.42em;
    height: 48px;
    width: 48px;
  }
}

.shareButton {
  flex-shrink: 0;
}
