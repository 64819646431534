@use 'src/styles/theme';

.root {
  padding-top: 10px;
  width: 700px;

  @include theme.breakpointDown('md') {
    padding-top: 0;
    width: 100vw;
    height: 100vh;
  }
}

.clearIcon {
  position: absolute;
  color: theme.$l4;
  top: 16px;
  right: 16px;
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.searchIcon {
  height: 16px;
  width: 16px;
  margin-right: 10px;
  color: theme.$d5;
}

.backIcon {
  margin-right: 10px;
  height: 30px;
  width: 30px;
  color: theme.$action-primary-main;
  cursor: pointer;
}

.header {
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  padding: 15px;
}

.input {
  flex-grow: 1;
  border: none;
  outline: none;
  font-family: Roboto;
  font-size: 14px;
  line-height: 15px;
  color: theme.$black;

  ::placeholder {
    color: theme.$d5;
  }
}
