.root {
  background-color: transparent;
  display: block;
  padding: 24px;
  text-decoration: none;
  text-transform: none;
  width: 100%;

  &,
  &:active:not([disabled]) {
    box-shadow: none;
  }
}
