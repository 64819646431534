@use 'src/styles/theme';

.root {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &::before {
    z-index: 10;
  }

  &::after {
    z-index: 10;
  }
}

.content {
  flex-basis: 100%;
  overflow: auto;
}
