@use 'src/styles/theme';

.container {
  position: relative;
  width: 130px;

  @include theme.breakpointDown('md') {
    width: 97.5px;
  }
}

.icon {
  height: auto;
  width: 80%;
}

.beta {
  position: absolute;
  right: 0;
  top: 0;
  width: 23%;

  @include theme.breakpointDown('md') {
    font-size: 6.7px;
  }
}
