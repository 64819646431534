@use 'src/styles/theme';

$addButtonKey: addButton;

.list {
  background-color: rgba(0, 0, 0, 0.25);
  width: 100%;

  :global {
    .Blockhead__ListItem__Root {
      transition: background-color 200ms ease-out;

      &[aria-selected='true'],
      &[data-hovered='true'] {
        background-color: inherit;
      }
    }

    .Blockhead__ListItem__Root[aria-disabled='true']:not([data-key='#{$addButtonKey}']) {
      cursor: default;
    }

    .Blockhead__ListItem__GridCell {
      padding: 24px;
    }
  }
}

.addRow {
  border-radius: 16px;
  border: 2px dashed theme.$action-primary-main;
  border-style: dashed;
  color: theme.$action-primary-main;
  height: 50px;
  width: 100%;

  :global(.Blockhead__ListItem__Root)[data-key='#{$addButtonKey}'][aria-disabled='true']
    & {
    opacity: 0.6;
  }
}

:export {
  addButtonKey: $addButtonKey;
}
