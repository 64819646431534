@use 'src/styles/theme';

.root {
  padding: 36px 0 64px;
  margin: 0;
  font-family: theme.$primary-heading-font;
  font-size: 20px;
  line-height: 20px;
  color: theme.$black;
}
