@use 'src/styles/theme';

.root {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
}

.header {
  padding: 0 20px;
}

.list {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;

  :global {
    .Blockhead__ListItem__GridCell {
      padding: 0;
    }
  }
}

.link {
  text-decoration: none;
  color: unset;
}

.rowInner {
  flex-grow: 1;
  width: 100%;
  padding: 4px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &__withBorder {
    border-top: 1px solid theme.$l4;
  }
}

.rowImage {
  height: 45px;
  width: 45px;
  margin-right: 15px;
  border-radius: 8px;
  box-shadow: 0 1px 10px 0 rgba(#000, 0.15);
}

.infoSection {
  display: flex;
  flex-direction: column;
}

.primaryText {
  text-align: left;
  line-height: 16px;
}

.secondaryText {
  margin-top: 10px;
  font-size: 11px;
  line-height: 11px;
  color: theme.$black;
  text-align: left;
}

.loader {
  padding: 64px;
}
