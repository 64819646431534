.video {
  bottom: 0;
  height: 100%;
  object-fit: cover;
  min-height: 100%;
  min-width: 100%;
  position: fixed;
  right: 0;
  width: 100%;
}
