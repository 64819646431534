@use 'src/styles/theme';

.banner {
  background-image: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.75) 0%,
      rgba(255, 255, 255, 0.75) 100%
    ),
    linear-gradient(112deg, #5dbefc 0%, #dd92fe 100%);
  padding: 24px 0px;
}
