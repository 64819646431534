@use 'src/styles/theme';

.spinner--wave {
  height: auto;
  width: auto;

  & > div {
    height: 18px;
    width: 18px;
    margin-left: 3px;
  }
}

.spinner--blue {
  & > div {
    background-color: theme.$action-primary-main;
  }
}
