@use 'src/styles/theme';

.header {
  padding: 0px 24px;
}

.hero {
  font-size: 60px;

  @include theme.breakpointDown('md') {
    font-size: 24px;
  }
}

.actions {
  width: 100%;
}
