.badge {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 65px;
  display: flex;
  height: 48px;
  justify-content: center;
  padding: 0 16px;
}

.icon {
  flex-shrink: 0;
  height: 16px;
  width: 16px;
}

.text {
  flex-shrink: 0;
}
