@use 'src/styles/theme';

.button {
  background: rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  border: none;
  height: 75px;
  position: relative;

  &::after {
    border: 2px dashed theme.$action-primary-main;
    border-radius: inherit;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
    transition: opacity 150ms ease-out;
  }

  &[data-hovered='true']::after {
    opacity: 1;
  }
}
