@use 'sass:color';
@use 'src/styles/theme';

.page {
  min-height: 100vh;
}

.body {
  min-height: 100vh;
  position: relative;

  &::after {
    background-image: linear-gradient(
      180deg,
      color.change(theme.$d2, $alpha: 0.38) 0%,
      color.change(theme.$d2, $alpha: 0.75) 100%
    );

    position: absolute;
    content: '';
    inset: 0;
  }
}

.nav {
  z-index: 2;
}
