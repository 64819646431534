@use 'src/styles/theme';

.contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: inherit;
  padding: 100px 16px;
  position: relative;
  z-index: 1;
}

.card {
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(50px);
  padding: 80px 24px 24px;
  width: 900px;
}
